@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

body,
html {
  margin: 0;
  padding: 0;
  background: $background;
  font-family: "f1";
}

// ::-webkit-scrollbar {
//     width: 2px;
//     background: #6997FF;
// }

h2,
h3,
h4,
h5,
h6 {
  font-family: "f3";
}

h1 {
  color: $primary2;
  font-family: "f4";
}

p {
  font-size: 16px;
}

a {
  text-decoration: none;
  color: $black !important;
}

a:hover {
  color: $black;
}

ul {
  list-style: none;
  padding: 0;
}

.w-90 {
  width: 90%;
  margin: auto;
}

.w-95 {
  width: 95%;
  margin: auto;
}

.w-80 {
  width: 80%;
  margin: auto;
}

.w-50 {
  width: 50%;
  margin: auto;
}

.w-lg-80 {
  width: 80%;
  margin: auto;
}

.hv-100 {
  height: 100vh;
}

.hv-50 {
  height: 50vh;
}

.bg-white {
  background: $white;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.d-flex-al {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.d-flex-al-jc {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.d-flex-jc {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.d-flex-al-jb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.d-flex-al-ja {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.cent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

:focus-visible {
  outline: 0 !important;
}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0;
}

.btn:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 0%) !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

.login-btn {
  width: 150px;
  border: 0;
  border-radius: 10px;
  background: $primary2;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  color: $white !important;
  font-family: "f2";
}

.btn-web {
  width: 150px;
  border: 0;
  border-radius: 20px;
  background: radial-gradient(#656aea, #1126b8);
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  color: $white !important;
  font-family: "f2";
  transition: 0.5s all;
}

.progress {
  height: 8px;
}

.progress-bar {
  background-color: $primary1;
}

button {
  border: 0 !important;
  background-color: #0a3f89 !important;
  text-align: center !important;
  width: 200px;
  transition: 0.5s all;
}

.btn:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0) !important;
}

.btn-primary {
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
}

// tabs//
.invest-type {
  .nav-item {
    margin-right: 10px;
  }

  img {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 100%;
    padding: 7px;
    box-shadow: 0px 0px 6px #8181816b;
  }

  .nav-link {
    background-color: $white;

    &.active {
      background-color: $primary2 !important;
      color: $white !important;
    }
  }
}

// tabs end//
// Login Start //

.login-page {
  flex-direction: column;
  height: 100vh;

  // position: absolute;
  // top: 0;
  .logo-img img {
    width: 200px;
  }

  .login-form {
    padding: 20px;
    border-radius: 10px;
    background: $white;
    box-shadow: 0 0 12px rgb(0 0 0 / 23%);
    text-align: center;

    img {
      width: 200px;
      margin: 10px auto;
    }

    h5,
    label,
    h6 {
      text-align: start;
      display: block;
    }
  }

  .login-bg {
    text-align: center;
    background: linear-gradient(45deg, #246ecb, #eaf6ff);
    // background: url(./assets/img/icon/login-main-image.jpg) no-repeat;
    align-items: center;
    display: flex;
    height: 100vh;
    left: 0;
    padding: 100px 50px;
    position: fixed;
    top: 0;
    width: 40%;

    img {
      width: 90%;
    }

    .log_fav {
      position: absolute;
      top: 5%;
      left: 5%;
      width: 50px;
    }
  }

  .log-img {
    position: relative;

    .login-bg {
      width: 73%;
    }

    .reg-bg {
      width: 80%;
    }

    .login-ab {
      position: absolute;
      top: 50%;
      left: 35%;
      transform: translate(-50%, -50%);

      img {
        width: 100%;
        margin: auto;
      }
    }
  }

  .login-form {
    // padding: 10px;

    h5 {
      color: $primary2;
      font-size: 23px;
      margin-bottom: 15px;
      // text-align: center;
    }

    .form-control {
      font-size: 13px;
      color: #000;
      width: 100%;
      /* background: #d8e4fa; */
      padding: 12px 15px;
      border-radius: 8px;
      margin-bottom: 13px;
      border: 0;
      font-family: "f2";
      /* height: 50px; */
      border: 1px solid #c7c7c7;
    }

    .log-dont {
      margin-top: 15px;
      text-align: center;
      font-size: 15px;
      color: $black;
      font-family: "f2";

      a {
        color: $primary2 !important;
      }
    }
  }
}

// login  End//
// Nav Bar start//
.top-header {
  background: #f9f9f7;
  box-shadow: 0 0 6px #54545429;

  .nav-design {
    img {
      width: 90%;
    }

    a {
      font-size: 18px;
      color: $black;
      margin: auto;

      &.active {
        color: $primary1 !important;
      }
    }
  }

  .head-user {
    .dropdown-menu[data-bs-popper] {
      top: 120%;
      background: #ebf5fd;
      border: 0;
      box-shadow: 0 0 3px #00000029;
      left: -53px;
    }

    .ref_box {
      border: 2px solid #0a3f89;
      padding: 7px;
      border-radius: 5px;
      margin: 0 10px;
      font-family: "f2";

      span {
        color: #0a3f89 !important;
      }
    }

    button {
      background-color: transparent !important;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black !important;
      width: auto !important;
    }

    .drop-img img {
      width: 20px;
      margin-right: 10px;
    }

    .drop-img a {
      font-size: 16px;
      color: $primary2;
      font-family: "f2";
    }

    .icon-bg {
      width: 35px;
      height: 35px;
      background: $primary3;
      color: $black;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 5px #34333340;
    }

    .user-bg {
      width: 45px;
      height: 45px;
      background: linear-gradient(132deg, $primary2, $blue);
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 5px #34333340;
      position: relative;
      margin: 0;

      img {
        width: 38px;
        height: 38px;
        border-radius: 100%;
        // margin-top: 7px;
      }
    }

    .dropdown-toggle::after {
      display: none;
    }

    .user-name {
      background: $primary3;
      border-radius: 0 10px 10px 0;
      padding: 5px 21px;
      // margin-left: -10px;
      color: $black;
      font-size: 16px;
      font-family: "f2";
      margin-bottom: 0;
      position: relative;

      &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }
}

.toggle {
  overflow: hidden;
  width: 40px;
  height: 40px;
  background-color: $primary1 !important;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .line-toggle {
    position: absolute;
    display: block;
    width: 30px;
    height: 3px;
    background: $white;
    border-radius: 5px;
    transition: all 0.6s;

    &:first-child {
      transform: translateY(-10px) translateX(-8px);
      width: 15px;
      transition-delay: 0s;
    }

    &:nth-child(2) {
      transition-delay: 0.12s;
    }

    &:last-child {
      transform: translateY(10px) translateX(-6px);
      width: 19px;
      transition-delay: 0s;
    }
  }
}

// Nav Bar end//
// Dashboard //
.top-head {
  h5 {
    background: $white;
    width: 90%;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    text-transform: uppercase;
    color: $primary2;
    box-shadow: 0 0 4px #7472721f;
  }
}

.dash-box {
  padding: 20px;
  border-radius: 15px;
  position: relative;
  overflow: hidden;

  .cir-dash {
    position: absolute;
    top: 0;
    width: 100%;
    right: 0;
  }

  .icon-box {
    background: $white;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    box-shadow: 0 0 4px #7472721f;
    position: relative;
    z-index: 1;

    img {
      padding: 2px;
      width: 35px;
    }
  }

  .count-value {
    position: relative;
    z-index: 1;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  h5 {
    color: $white;
    font-size: 22px;
    font-family: "f2";
  }

  p {
    font-family: "f2";
    color: $white;
    font-size: 18px;
    margin: 0;
  }
}

.box-first {
  background: linear-gradient(142deg, #ae93eb, #a071ff);
}

.box-two {
  background: linear-gradient(142deg, #ffdb83, #fec34b);
}

.box-three {
  background: linear-gradient(142deg, #a8c6fc, #6997ff);
}

.box-four {
  background: linear-gradient(142deg, #fccdab, #fe8e4f);
}

.earn-inv {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;

  h4 {
    color: $primary2;
    font-size: 25px;
    margin-bottom: 15px;
  }

  h6 {
    font-family: "f2";
    font-size: 20px;
    color: $black;
  }

  p {
    text-align: justify;
    line-height: 2;
  }

  h5 {
    color: $primary2;
    text-align: start;
  }

  .leas-box {
    background: $white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px rgb(116 114 114 / 12%);

    h6 {
      font-family: "f3";
    }

    .cir-cle {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #fcefda;
    }

    .real-box {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #d9f5ff;
    }

    p {
      line-height: 1.8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      color: $black;
    }
  }
}

.carousel-control.right:hover,
.carousel-control-next:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(0, 0, 0, 0.0001)),
    to(rgb(0 0 0 / 0%))
  ) !important;
}

.carousel-control.left:hover,
.carousel-control-prev:hover {
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgb(0 0 0 / 0%)),
    to(rgb(0 0 0 / 0%))
  ) !important;
}

.carousel-indicators {
  display: none;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
  z-index: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(83%) sepia(247%) saturate(687%) hue-rotate(156deg) brightness(181%) contrast(164%);
}

.you-inves {
  margin-top: 15px;
  margin-bottom: 15px;

  h5 {
    color: $primary2;
    text-align: center;
    font-size: 25px;
  }
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa0 !important;
}

.react-tabs__tab {
  padding: 12px 18px !important;
  margin-right: 15px;
  border-radius: 8px !important;
  background: $white;

  &--selected {
    background: $primary2 !important;
    border-color: #aaa0 !important;
    color: $white !important;
  }
}

.foo {
  width: 100% !important;
  height: 500px !important;
}

.gen-info {
  margin-top: 35px;
  text-align: center;

  h5 {
    color: $primary2;
    font-size: 25px;
  }
}

.over-view {
  margin-top: 25px;
  margin-bottom: 15px;
  overflow-x: auto;

  h5 {
    color: $primary2;
    font-size: 25px;
  }

  .project-view {
    width: 100%;
    background: rgb(255 255 255 / 52%);
    border-radius: 10px;

    thead {
      background: $primary3;

      th {
        padding: 15px;
        font-size: 20px;
      }
    }

    tbody {
      td {
        background: transparent;
        color: #393939;
        padding: 15px;
        border-bottom: 1px solid #dddddd7d;
      }
    }
  }
}

.comple-kyc {
  background: $white;
  padding: 10px;
  border-radius: 15px;
  box-shadow: 0 0 12px #0000001f;

  img {
    width: 70%;
    margin: auto;
  }
}

// Dashboard End//
// Investment start //
.inve-page {
  .invest-conten {
    .sticky-fun {
      position: sticky;
      top: 10%;
    }

    .invest-list {
      background: $primary3;
      padding: 10px;

      .nav-item {
        margin-top: 10px;
      }

      .nav-link.active {
        color: #40a13f !important;
        background: #f0fef0 !important;
      }
    }

    .invet-cross {
      position: relative;

      .in-abs {
        position: absolute;
        top: 0;
        color: #fff;
        // padding: 16px;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 15px;
          opacity: 0.7;
          margin-bottom: 0;
        }
      }
    }
  }

  .leasing-ti {
    h5 {
      color: $primary2;
      font-size: 22px;
    }

    p {
      text-align: justify;
    }

    .login-btn {
      margin: inherit;
    }
  }

  .inves-box {
    background: $white;
    padding: 13px;
    border-radius: 10px;
    position: relative;
    box-shadow: 0 0 6px #00000021;
    overflow: hidden;
    cursor: pointer;

    &:hover .view-detail {
      bottom: 0%;
      transition: 0.9s all;
    }

    .view-detail {
      position: absolute;
      bottom: -30%;
      width: 100%;
      left: 0;
      height: 60px;
      background: $primary2;
      transition: 0.9s all;

      a {
        color: $white !important;

        svg {
          font-size: 20px;
          margin-left: 10px;
        }
      }
    }

    p {
      margin-bottom: 10px;
      color: rgb(0 0 0 / 70%);
    }

    span {
      font-family: "f3";
      color: #000;
    }

    h6 {
      font-family: "f2";
    }

    h4 {
      color: $primary2;
      font-size: 17px;
    }

    h5 {
      font-size: 18px;
      margin-bottom: 15px;
    }

    .compl-leas h6 {
      color: $primary1;
    }

    button {
      background-color: $primary2 !important;
      width: 100%;
    }
  }
}

// Investment end //
// investment view//
.inve-view {
  .inves-head {
    h5 {
      color: $primary2;
    }

    .inves-baner {
      position: relative;

      button {
        background-color: $primary1 !important;
        border: 0;
        position: absolute;
        top: 16px;
        right: 16px;
        width: 50%;
      }

      img {
        border-radius: 15px;
      }
    }

    .due-dat {
      h6 {
        font-size: 18px;
        color: $black;

        span {
          font-family: "f2";
        }
      }
    }

    .inv-details {
      background: $white;
      box-shadow: 0 0 6px #00000021;
      padding: 12px;

      p {
        margin-bottom: 12px;
      }

      h6 {
        font-size: 18px;
        color: $black;
        margin: 0;
      }

      h4 {
        color: $primary1;
        margin: 0;
        font-size: 18px;
      }
    }

    .abt-company p,
    .inv-faq p {
      text-align: justify;
      line-height: 2;
    }
  }
}

.invest-now {
  background: $primary3;
  box-shadow: 0 0 4px #0000002b;
  border-radius: 7px;
  overflow: hidden;
  position: sticky;
  top: 10%;

  .inve-calc {
    background: $white;
    padding: 10px;

    h5 {
      color: $primary1;
    }

    .input-des {
      position: relative;

      input {
        width: 100%;
        border: 0;
        font-family: "f3";
        border-radius: 7px;
        background: #efefef;
        padding: 10px 10px 10px 55px;
      }

      .rupes {
        width: 30px;
        height: 30px;
        border-radius: 7px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 6px;
        left: 15px;
        color: $primary2;
      }

      span {
        background: $primary3;
        padding: 7px;
        font-size: 13px;
        border-radius: 9px;
        margin: 0;
        cursor: pointer;
      }

      .arrow-des {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $white;
        position: absolute;
        bottom: -45px;
        left: 50%;
        transform: translate(-50%);

        img {
          width: 25px;
        }
      }
    }
  }

  .leasing-calc {
    padding: 17px;
    text-align: center;

    h5 {
      font-size: 17px;
    }

    h6 {
      font-family: "f2";
    }

    .tol-des {
      padding-top: 15px;
      border-top: 1px solid #ddd;
      height: 54px;
      overflow: hidden;
      h4 {
        color: $primary2;
        font-size: 18px;
      }
    }
  }

  button {
    background-color: $primary1 !important;
    border: 0;
    text-align: center;
    width: 300px;
  }

  .con-btn {
    button {
      background-color: $primary2 !important;
      border: 0;
      text-align: center;
      width: 200px;
    }
  }

  .toggle-switch {
    h6 {
      margin: 0 10px;
      font-family: "f2";
    }

    .checkbox {
      display: none;
    }

    .label {
      display: block;
      overflow: hidden;
      cursor: pointer;
      border: 0 solid #bbb;
      border-radius: 20px;
      position: relative;
      width: 55px;
    }

    .inner {
      display: block;
      width: 200%;
      margin-left: -100%;
      transition: margin 0.3s ease-in 0s;

      &:before {
        content: " ";
        padding-left: 10px;
        background: linear-gradient(#5fbc5f, #009300);
      }

      &:after {
        content: " ";
        padding-right: 10px;
        background: #bbb;
      }

      &:before,
      &:after {
        float: left;
        width: 50%;
        height: 27px;
      }
    }

    .switch {
      display: block;
      width: 19px;
      height: 19px;
      margin: 4px;
      background: #fff;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 25px;
      border: 0 solid #bbb;
      border-radius: 20px;
      transition: all 0.3s ease-in 0s;
    }

    .checkbox:checked + .label .inner {
      margin-left: 0;

      h6 {
        color: $primary1;
      }
    }

    .checkbox:checked + .label .switch {
      right: 0px;
    }
  }
}

.payment-modal {
  .modal-header {
    padding: 0;
    border-bottom: 1px solid #dee2e600;

    .btn-close {
      position: absolute;
      right: 0;
      width: 15px;
      height: 15px;
      background-color: #1262a3;
      opacity: 1;
      border-radius: 100%;
      padding: 7px;
      filter: brightness(133.5) invert(89);
    }
  }

  .moday-tit {
    padding: 15px;

    h6 {
      margin: 0 10px;
    }

    img {
      width: 25px;
    }

    button {
      background-color: $primary3 !important;
      border: 0;
      color: $black;
    }

    .paydetail-view {
      width: 100%;
      background: $primary3;

      thead {
        th {
          color: $primary2;
          padding: 15px;

          border-bottom: 1px solid #ddd;
        }
      }

      tbody {
        td {
          padding: 15px;
          border-bottom: 1px solid #ddd;
        }

        .total-tit td {
          font-family: "f3";
          color: $primary2;
        }
      }
    }
  }
}

.kyc-pop {
  text-align: center;
  padding: 10px;

  h5 {
    margin-top: 14px;
  }

  a {
    background-color: $primary2 !important;
    border: 0;
    text-align: center;
    width: 200px;
    color: $white !important;
  }
}

.chos-mod {
  padding: 10px;

  .choos-pay {
    h5 {
      font-size: 18px;
      color: $primary2;
    }

    .form-check-inline {
      margin: 10px;

      label {
        font-size: 17px;
        font-family: "f2";
      }
    }

    a {
      width: 200px;
      background: $primary2;
      color: $white !important;
    }
  }
}

.past-cal {
  text-align: center;

  h6 {
    color: $primary2;
    font-size: 18px;
  }
}

// investment view end//
// My Investment //
.no-invest {
  h6 {
    margin: 15px;
    font-size: 18px;
  }
}

.invest-det {
  .invest-box {
    background: $white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 0 4px #60525240;

    button {
      width: 100px;
      background-color: #04860d !important;
    }

    h6 {
      font-family: "f2";
      margin: 15px 0;
      font-size: 17px;
    }

    h5 {
      font-family: "f2";
      margin: 15px 0;
      font-size: 17px;
      color: $primary2;
    }

    img {
      width: 100px;
    }
  }
}

.invoice-btn {
  color: $primary2 !important;
  border: 2px solid $primary2 !important;
  background-color: transparent !important;
}

.leasing-calc {
  h6 {
    margin: 10px 0;
    font-size: 16px;
  }

  h5 {
    color: $primary2;
    margin: 10px 0;
    font-size: 18px;
  }
}

.no-tickets {
  height: 68vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

// My Investment end//
// Ticket //
.sup-modal {
  padding: 15px;
  text-align: center;

  h5 {
    color: $primary2;
  }

  .form-control,
  .form-select {
    margin: 15px 0;
    background: $primary3;
    padding: 10px 15px;
    border: 1px solid #ced4da00;
    font-size: 14px;
    font-weight: normal;
  }

  ::placeholder {
    color: #91969c;
  }
}

.new-ticket {
  overflow-x: auto;

  table {
    background: $primary3;
    width: 100%;
    border-radius: 13px;
    text-align: center;

    th {
      padding: 15px;
      color: $primary2;
    }

    tbody {
      tr {
        background: $white;
        border: 10px solid #ebf5fd;
        border-radius: 7px;

        td {
          padding: 15px;

          button {
            width: auto;
            background-color: #c5edee !important;
            color: #1bc778 !important;
          }

          img {
            width: 40px;
          }

          svg {
            color: $primary2;
          }

          span {
            margin-top: 5px;
          }

          .CircularProgressbar {
            width: 50px;

            .CircularProgressbar-text {
              fill: #000;
            }
          }
        }
      }
    }
  }

  .ticket-page {
    text-align: end;

    button {
      width: auto;
      background-color: $primary1 !important;
    }
  }
}

.edit-ticket {
  overflow-x: auto;

  table {
    background: $primary3;
    width: 100%;
    border-radius: 13px;

    a {
      color: $white !important;
      width: auto;
      background-color: $primary1 !important;
    }

    th {
      padding: 10px 20px;
      color: $primary2;
      font-size: 18px;
    }

    tbody {
      display: flex;
      padding: 10px;

      tr {
        display: grid;
      }

      td {
        padding: 12px;

        button {
          width: auto;
          background-color: #c5edee !important;
          color: #1bc778 !important;
        }

        img {
          width: 40px;
        }

        svg {
          color: $primary2;
        }

        span {
          margin-top: 5px;
        }

        .CircularProgressbar {
          width: 50px;

          .CircularProgressbar-text {
            fill: #000;
          }
        }
      }
    }
  }
}

.conv-sation {
  label {
    color: $primary2;
    font-size: 18px;
    font-family: "f3";
  }

  .client-chat {
    background: $white;
    padding: 15px;
    width: 70%;
    border-radius: 10px;
    box-shadow: 0 0 4px rgb(116 114 114 / 42%);
  }

  .user-chat {
    background: #d9f5ff;
    padding: 15px;
    width: 70%;
    border-radius: 10px;
    box-shadow: 0 0 4px rgb(116 114 114 / 42%);
    margin: 0 auto;
  }

  p {
    margin: 0;
  }
}

// Ticket end//
// Refer & Earn //
.ref-earn {
  h5 {
    text-align: center;
  }

  .ref-box {
    background: $white;
    padding: 15px;
    border-radius: 7px;
    box-shadow: 0 0 4px #00000040;

    h4 {
      color: $primary2;
      font-size: 18px;
    }

    input {
      padding: 4px 12px;
      border: #ffff;
      background: #fff;
      box-shadow: 0 0 3px #00000026;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-item {
      display: block;
      width: 33.3%;
    }

    button {
      width: auto;
    }

    .ref_boxs {
      position: relative;

      input {
        padding: 15px;
        border: 1px solid #00000047;
      }

      .inv_btn {
        background: #e4edfa;
        border-radius: 8px;
        color: #0a3f89 !important;
        padding: 12px;
        position: absolute;
        top: 3px;
        right: 9px;
        font-family: "f3";
      }
    }
  }

  .ref-deta {
    background: #b8e4ff;
    padding: 15px;
    text-align: center;

    h4 {
      font-size: 15px;
      font-family: "f1";
      color: #18b718;
    }

    img {
      margin: 10px 0;
      width: 80px;
    }
  }

  .your-ref {
    h5 {
      text-align: start;
      color: $primary2;
    }

    .init-tab {
      width: 40px;
      height: 40px;
      background: $primary2;
      border-radius: 100%;
      color: $white;
      padding: 0;

      margin: 10px;
    }

    table {
      width: 100%;

      tr {
        background: $white;
        border: 10px solid $primary3;

        padding: 10px;

        .amot-tab {
          color: $primary1;
        }
      }
    }
  }
}

.dropdown-item:hover {
  background-color: #e9ecef00;
}

// Refer & Earn  end//
// Raise //
.raise-cap {
  h5 {
    font-size: 22px;
    color: $primary2;
  }

  p {
    line-height: 2;
  }

  .suc-he {
    height: 70vh;
  }

  .raise-box {
    background: $white;
    padding: 25px;
    border-radius: 10px;
    box-shadow: 0 0 4px #0000002b;

    .form-control,
    .form-select {
      background-color: #d6ddf9;
      margin-top: 15px;
      padding: 10px;
      border: 0;
      font-size: 14px;
    }

    ::placeholder {
      color: #6c6a69;
      font-size: 14px;
    }

    label {
      color: $black;
      font-family: "f2";
    }

    h5 {
      text-align: center;
    }

    h6 {
      color: $primary1;
      font-size: 22px;
    }
  }
}

// Raise end//
// Notification //
.notify-page {
  table {
    width: 100%;
    background: $white;

    tr {
      border: 12px solid $background;

      td {
        padding: 10px;

        .til-span {
          font-size: 18px;
          font-family: "f3";
          color: $primary2;
        }
      }
    }

    img {
      width: 60px;
    }
  }
}

// Notification end//
//  profile //
.prof-page {
  .profile-box {
    background: $white;
    box-shadow: 0 0 10px #0003;
    padding: 20px;
    border-radius: 10px;
    text-align: center;

    .prof-imag {
      position: relative;

      .prf-img {
        width: 150px;
        height: 150px;
        border-radius: 100%;
      }

      .prf-abs {
        position: absolute;
        bottom: -5%;
        right: 45%;
        width: 40px;

        transform: translate(100%);
      }
    }

    .profile-list {
      h5 {
        color: $primary2;
      }

      a {
        font-family: "f2";
        word-wrap: break-word;
      }

      li {
        margin-top: 25px;
      }
    }
  }

  .prof-nav {
    .profile-type {
      width: 390px;
      background-color: $primary2;
      border-radius: 5px;

      .nav-link {
        background-color: $primary2;
        color: $white !important;
        padding: 10px;
        margin-right: 10px;

        &.active {
          background-color: $primary1;
          border-radius: 5px;
        }

        img {
          width: 20px;
        }
      }
    }
  }

  .edit-prf {
    .form-control {
      padding: 10px 12px;
      border: 0;
      box-shadow: 0 0 6px #cbe6fbd1;
    }

    label {
      color: $primary2;
      font-family: "f3";
      font-size: 18px;
    }

    ::placeholder {
      color: #00000094;
      font-size: 15px;
    }

    .input-group {
      button {
        border-radius: 0 6px 6px 0;
        width: auto;
        padding: 10px 12px;
      }

      .form-control {
        border-radius: 6px 0px 0 6px;
      }
    }
  }
}

//  profile end//

// Frontend design  //
.front-design {
  background: $white;
}

.top-bans {
  position: relative;
}

.front-head {
  h5 {
    font-size: 25px;
    font-family: "f4";
    text-transform: uppercase;
    position: absolute;
    top: 0;
    color: $white;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.baner-page {
  img {
    width: 100%;
    height: 30vh;
  }
}

// nav bar //
.top-navbar {
  position: relative;
  z-index: 1;

  .fron-nav {
    background: $primary2;

    .logo_imag {
      background: $white;
      display: flex;
      align-items: center;
      height: 85px;
      justify-content: center;
      box-shadow: 0 0 12px #00000024;
      position: absolute;
      top: 0;
      border-radius: 0 0 10px 10px;
      left: 10%;
      padding: 0 9px;

      img {
        width: 200px;
      }
    }

    .nav_a {
      .nav-link {
        color: $white !important;
        font-size: 15px;

        &.active {
          color: $primary1 !important;
        }
      }
    }

    .fro_bt {
      border: 2px solid #fff;
      border-radius: 5px;
      padding: 7px 10px 3px !important;
    }
  }
}

// nav bar end//
// Home page //
.home-page {
  h5 {
    color: #0a3f89;
    font-size: 30px;
    font-family: "f4";
  }

  .grow-inve {
    .grow-con {
      p {
        line-height: 2;
      }
    }

    .cou-design {
      // background: radial-gradient(#84e57e, #319f31);
      padding: 10px;
      box-shadow: 0 0 10px #cce5f1;
      border-radius: 10px;
      text-align: center;
      h6 {
        // color: $white;

        margin: 10px 0;
      }

      img {
        width: 80px;
      }

      h4 {
        font-family: "f2";
        font-size: 15px;
        margin: 10px 0;
        // color: $white;
      }

      .hex-count {
        text-align: center;

        .hex-des {
          position: relative;
          width: 150px;
          height: 150px;
          display: flex;
          background: radial-gradient(#84e57e, #319f31);
          clip-path: polygon(1% 23%, 50% 0, 99% 22%, 99% 77%, 50% 100%, 0 78%);
          align-items: center;
          justify-content: center;
          margin: auto;
          box-shadow: 0 0 12px #0000005c;

          h6 {
            color: $white;
            position: absolute;
            bottom: 25px;
            margin: 0;
            text-align: center;
          }

          img {
            width: 57px;
            margin-bottom: 25px;
          }
        }

        h4 {
          font-family: "f2";
          font-size: 18px;
        }
      }
    }
  }

  .rio-works {
    background: $primary3;
    padding: 30px;
    text-align: center;

    h6 {
      color: $primary2;
      font-family: "f4";
      margin: 0;
      font-size: 25px;
    }

    p {
      font-size: 16px;
      line-height: 2;
      width: 90%;
      margin: auto;
      font-family: "f2";

      span {
        color: $primary2;
      }
    }

    .nav-tabs {
      border-right: 3px solid #0a3f891f;
      position: relative;
      border-bottom: none;

      .rio_li {
        margin-bottom: 20px;

        .rio_tab {
          position: relative;
          display: block;
          z-index: 1;
          transition: all 0.3s ease 0s;
          font-size: 18px;

          &:hover::before,
          &.active::before {
            top: 50%;
            opacity: 1;
          }

          &hover::after,
          &.active::after {
            right: -6px;
            opacity: 1;
          }

          &.active {
            background: $white;
            color: $primary2 !important;
            font-family: "f4";
            border-radius: 5px 0px 0px 5px;
          }

          &::before,
          &::after {
            content: "";
            height: 20px;
            width: 20px;
            background-color: #fff;
            border-radius: 5px;
            border: 2px solid #0a3f89;
            opacity: 0;
            transform: translateY(-50%) rotate(45deg);
            position: absolute;
            right: -10px;
            top: 100%;
            z-index: -1;
            transition: all 0.3s ease 0s;
          }

          &::after {
            background-color: $primary2;
            border-radius: 50%;
            height: 12px;
            width: 12px;
            right: 100%;
            top: 50%;
          }
        }
      }
    }

    .rio_tabc {
      .rio_tabtext {
        h3 {
          color: $primary1;
        }

        .mar_k {
          width: 80% !important;
          margin: auto;
        }

        img {
          width: 250px;
        }
      }
    }
  }

  .rio-work {
    // background: $primary3;
    padding: 15px;

    text-align: center;

    p {
      text-align: start;
      line-height: 1.8;
    }

    .rio-box {
      background: $white;
      padding: 15px;
      // box-shadow: 0 0 5px rgb(0 0 0 / 17%);
      box-shadow: 0 7px 20px #2196f32e;
      transition: 0.5s ease-in-out;
      height: 100%;

      &:hover {
        img {
          right: 19px !important;
          transition: 0.5s ease-in-out;
        }
      }

      .cir-design {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        background: #c8defb;
        position: relative;
        box-shadow: 0 0 5px #00000063;
        margin: auto;

        img {
          width: 70px;
          position: absolute;
          right: -14px;
          top: 13px;
        }
      }

      h6 {
        color: $primary1;
        font-size: 20px;
      }

      p {
        text-align: center;
        line-height: 2;
      }
    }
  }

  .testi-monial {
    background: #ededed;
    padding: 15px;
    text-align: center;

    h6 {
      color: $primary2;
      font-family: "f4";
      font-size: 20px;
    }

    .tex-box {
      background: $white;
      padding: 20px;
      border-radius: 10px;
      p {
        text-align: justify;
        line-height: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
      }

      h4 {
        font-size: 20px;
      }

      h6 {
        font-family: "f1";
      }

      h3 {
        font-family: "f2";
        font-size: 18px;
        color: #000;
      }

      .clien-cir {
        width: 85px;
        height: 85px;
        border-radius: 100%;
        margin: auto;
        position: relative;
        border: 2px solid $primary2;

        img {
          border-radius: 100%;
          height: 100%;
        }
      }
    }
  }

  .client-sess {
    .clie-box {
      border: 1px solid #ddd;
      background: #fff;
      display: flex;
      align-items: center;
      padding: 10px;
      height: 150px;
      border-radius: 8px;
      // box-shadow: 0 0 5px #0000004a;

      img {
        width: 170px;
        margin: auto;
      }
    }
  }
}

.how-rio {
  position: relative;

  p {
    line-height: 2;
    width: 80%;
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
  }

  .hover-rio {
    background: $primary2;
    border-radius: 10px;
    padding: 3px;
    position: relative;
    box-shadow: 0 0 5px #0003;
    overflow: hidden;

    p {
      margin: auto;
      position: relative;
      z-index: 1;
    }

    &::after {
      width: 100%;
      background: $white;
      position: absolute;
      content: "";
      top: 0%;
      transition: 0.8s all;
      height: 100%;
      border-radius: 10px;
      left: 5px;
    }

    &:hover::after {
      background: $primary2;
      transition: 0.8s all;
    }

    &:hover p {
      transition: 0.9s all;
      color: #fff;
    }
  }
}

.owl-theme .owl-dots {
  display: none;
}

.blog-sess {
  h5 {
    color: $primary2;
  }

  .blog-box {
    .blog-ses {
      background: #f1eeee;
      padding: 15px 15px;
      border-radius: 0 0 10px 10px;

      h4 {
        font-size: 18px;
        text-align: justify;
        line-height: 1.7;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
      }

      p {
        text-align: start;
        line-height: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        margin: 0;
        height: 95px;
      }
    }
  }
}
// naren-start
.chat-box {
  display: flex;
  flex-direction: column;
  padding: 11px;
  background-color: #ffff;
  border-radius: 0.7rem;
  max-width: 510px;
  min-width: 230px;
  max-height: 210px;
  min-height: 110px;
}

.pointer {
  cursor: pointer;
}

.blog-title-box {
  max-width: 7rem;
  max-height: 2rem;
  font-size: 13px;
  text-align: start;
  color: $primary2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

.refel-table-body {
  tr {
    margin: 11px 0;
    td {
      padding: 11px;
    }
  }
}

.refer-modal-header-box {
  display: flex;
  justify-content: end;
  margin: 5px;
  padding: 5px;

  p {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 0;
  }
}

.blue-text {
  color: $primary2;
}
.blog-post-text {
  color: $primary2;
  background-color: #ffff;
  cursor: pointer;
  font-family: "f2";

  polyline {
    stroke: $primary2;
  }
}

.line-control-paragragh-box {
  line-height: 1.2em;
  height: 3.6em;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  overflow: hidden;
}

.social-med-blog {
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;
    margin-right: 20px;
    border-radius: 100%;

    img {
      width: 32px;
    }
  }
}

// naren end
// footer//
.foot-sess {
  position: relative;
  background: url(./assets/img/FEimg/footer.jpg) no-repeat;
  background-size: cover;
  height: 100%;

  .fot-page {
    color: $white;

    .logo-img {
      width: 240px;
      background: $white;
      padding: 10px;
      border-radius: 10px;
    }

    h5 {
      margin-top: 15px;
      color: $white;
      font-size: 20px;
    }

    .social-med {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background: $white;
        margin-right: 20px;
        border-radius: 100%;

        img {
          width: 20px;
        }
      }
    }

    .qu-link {
      li {
        margin-top: 25px;
        display: flex;
        align-items: center;

        a {
          color: $white !important;
          font-size: 15px;
        }

        img {
          width: 25px;
          margin-right: 10px;
        }
      }
    }
  }

  h6 {
    text-align: center;
  }

  .copy-img {
    filter: invert(17) brightness(7.5);
    width: 30px;
    margin: 0 5px;
  }
}

.aps_img {
  img {
    width: 150px;
  }

  p {
    margin: 0;
  }

  .ap_play {
    img {
      width: 120px;
      padding: 10px;
    }
  }
}

// end //
//Home page end //
// How to Invest //
.quick-page {
  .how-inv {
    h5 {
      text-align: center;
      color: $primary1;
      font-size: 22px;
    }

    .how-box {
      background: #f5f6fa;
      box-shadow: 0 0 3px #00000042;
      border-radius: 6px;
      padding: 10px;
      text-align: center;
      height: 100%;

      img {
        width: 200px;
        margin-bottom: 20px;
      }

      h4 {
        font-size: 18px;
        color: $primary2;
      }
    }
  }

  .ben-fits {
    .proce-bg {
      background: #fcefda;
      padding: 15px;

      p {
        text-align: justify;
        line-height: 2;
      }
    }

    h4 {
      color: $primary2;
    }
  }
}

// How to Invest end//
// our deal //
.our-deal {
  .how-inv {
    h5 {
      color: $primary1;
      text-align: center;
      font-size: 22px;
    }

    .inve-box {
      background: #f5f6fa;
      padding: 15px;
      border-radius: 8px;

      .inv_oh {
        height: 150px;
        overflow: hidden;
      }

      img {
        min-height: 150px;
      }
    }

    table {
      width: 100%;

      td {
        font-size: 14px;
      }
    }
  }

  .line-prj {
    width: 1px;
    height: 100%;
    background: #c4eaf8;
  }
}

.leas_dest {
  background: url(./assets/img/FEimg/bas.png) no-repeat;
  background-size: cover;
  height: 100%;

  // padding: 40px;
  ul {
    li {
      margin: 10px 0;
    }
  }

  h1 {
    font-family: "f4";
    color: $primary2;
    font-size: 30px;
  }
}

h4 {
  font-size: 24px;
  color: $primary1;
  font-family: "f4";
}

p {
  font-size: 16px;
}

button {
  width: auto;
  padding: 10px 14px;
}

.cou_tree {
  position: relative;
  text-align: center;

  img {
    width: 30%;
  }

  .coud_leas {
    position: absolute;
    background: #fff;
    width: 150px;
    padding: 10px;
    text-align: start;
    border-radius: 8px;
    top: -6%;
    left: 7%;

    p {
      margin: 0;
    }

    h5 {
      margin: 0;
      font-size: 19px;
    }
  }

  .coud_leas1 {
    position: absolute;
    background: #fff;
    width: 150px;
    padding: 10px;
    text-align: start;
    border-radius: 8px;
    top: 7%;
    right: 7%;

    p {
      margin: 0;
    }

    h5 {
      margin: 0;
      font-size: 19px;
    }
  }
}

.real_easta {
  .leas-box {
    height: 100%;

    p {
      text-align: justify;
      line-height: 2;
    }

    img {
      width: 300px;
      margin: 10px 0;
    }
  }
}

// our deal end//
//  project //
.part-box {
  background: linear-gradient(181deg, #d9f5ff, #fff);
  padding: 15px;
  border-radius: 10px;
  position: relative;

  h6 {
    color: $primary2;
    font-size: 20px;
  }

  img {
    width: 100px;
  }

  .cir-prj {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 2px solid $primary2;
    color: $primary2;
    background: $white;
    position: absolute;
    font-family: "f3";
    top: -24px;
    left: 10%;
  }
}

//  project end //
// leasign //
.leas-page {
  h5 {
    color: $primary2;
    font-family: "f4";
  }

  .why-leas {
    p,
    h6 {
      text-align: justify;
      line-height: 1.8;
    }

    h6 {
      font-family: "f1";
      color: $primary2;
    }
  }

  .ben-lea {
    h5 {
      text-align: center;
      font-family: "f4";
    }

    h1 {
      text-align: center;
    }

    .leas-box {
      background: $white;
      border-radius: 10px;
      padding: 12px;
      text-align: center;
      box-shadow: 0 10px 13px rgb(2 2 2 / 10%);
      height: 100%;

      .cir-beni {
        width: 130px;
        height: 130px;
        border-radius: 100%;
        background: #d9f5ff;
        overflow: hidden;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100px;
        }
      }
    }
  }

  .dif-leas {
    li {
      margin-top: 15px;
    }

    .dif_po {
      background: #dff6ff;
      padding: 20px 20px 15px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 13px rgb(2 2 2 / 10%);
      margin-bottom: 30px;

      p {
        margin: 0;
      }
    }

    .dif_po1 {
      background: #fcefda;
      padding: 20px 20px 15px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 13px rgb(2 2 2 / 10%);
      margin-bottom: 30px;

      p {
        margin: 0;
      }
    }

    .dif_po2 {
      background: #dbffe0;
      padding: 20px 20px 15px 20px;
      border-radius: 10px;
      box-shadow: 0 10px 13px rgb(2 2 2 / 10%);
      margin-bottom: 30px;

      p {
        margin: 0;
      }
    }
  }
}

.due_di {
  background: #dbffe0;
  padding: 20px;
  border-radius: 10px;
}

.cc_ic {
  color: #ffb400;
  margin-right: 5px;
}

.img_pos {
  position: relative;

  .abs_img {
    position: absolute;
    top: -45px;
    left: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      width: 180px;
    }
  }

  .des-box {
    background: $white;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    height: 100%;
    box-shadow: 0 10px 13px rgb(2 2 2 / 10%);

    ul {
      li {
        position: relative;
        text-align: start;
        margin-left: 20px;
        margin-top: 10px;

        &::before {
          position: absolute;
          top: 8px;
          content: "";
          width: 5px;
          height: 5px;
          background: $primary2;
          border-radius: 100%;
          left: -15px;
        }
      }
    }

    h5 {
      color: $primary1;
    }
  }
}

// leasign end//
// FAQ //
.faq-deal {
  .faq-secc {
    h5 {
      color: $primary2;
      font-size: 25px;
      font-family: "f4";
    }

    p {
      text-align: start;
      line-height: 2;
    }
  }

  .ac-faq {
    .acd-before {
      background: #0a3f89;
      border-radius: 10px;
      padding: 3px;
      position: relative;
      box-shadow: 0 0 5px rgb(0 0 0 / 20%);
      overflow: hidden;

      &::before {
        width: 100%;
        background: #fff;
        position: absolute;
        content: "";
        top: 0%;
        transition: 0.8s all;
        height: 100%;
        border-radius: 10px;
        left: 5px;
      }
    }

    .accordion-header {
      button {
        position: relative;
        z-index: 1;
        color: #000;
        background: transparent !important;
      }
    }

    .accordion-button:focus {
      box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    }

    .accordion-button {
      background-color: $primary2 !important;
      color: #fff;
      transition: 0.7s all;
    }

    .accordion-collapse {
      .accordion-body {
        background: $primary2;
        color: #fff;
        transition: 0.7s all;
        border-radius: 10px;
      }
    }
  }

  .help-deal {
    .help-ceny {
      img {
        width: 50px;
        height: 50px;
        background: $white;
        border-radius: 7px;
        padding: 10px;
        margin-right: 10px;
        box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
      }

      h5 {
        color: $primary2;
        margin: 0;
        font-size: 30px;
        font-family: "f4";
      }
    }
  }

  .faq_img {
    position: sticky;
    top: 5%;
  }

  .generfaq {
    position: sticky;
    top: 5%;
  }
}

.faq-pro {
  position: relative;

  .gener-tabs {
    img {
      width: 50px;
      height: 50px;
      background: $primary2;
      border-radius: 7px;
      padding: 10px;
      margin-right: 10px;
    }

    .nav-item {
      .nav-link {
        background-color: transparent;

        .box-des {
          font-size: 17px;
          font-family: "f3";
          background-color: #dbeafe;
          padding: 15px;
          border: 3px solid #fff;
          border-radius: 10px;
          box-shadow: 0 0px 6px #9b9b9b99;
        }

        &.active {
          .progress-bar {
            background-color: $primary2 !important;
          }
        }

        .progress {
          height: 80px;
          width: 9px;
          position: relative;

          .progress-bar {
            background-color: $primary3;
          }
        }
      }
    }
  }
}

.our-vis {
  img {
    margin-bottom: 20px;
  }

  .progra-line {
    position: absolute;
    top: 8px;
    background: #ebf5fd;
    width: 9px;
    right: 15.5%;
    height: 93%;
    border-radius: 4px;
    margin: auto;
    transform: translate(-50%, 1%);
  }

  // .progra-line {
  //   position: sticky;
  //   top: 5%;
  // }
}

// FAQ end//
// Blog view //
.blog-page {
  .blog-details {
    h5 {
      color: $primary2;
    }

    ul {
      margin: 15px 0;

      li {
        text-align: justify;
        margin: 15px 0;
      }
    }

    img {
      border-radius: 10px;
    }
  }

  .po-sta {
    position: sticky;
    top: 10%;

    .rec-tab {
      background: $white;
      box-shadow: 0 0 12px #00000029;
      padding: 10px;
      border-radius: 6px;

      img {
        width: 70px;
        height: 70px;
        border-radius: 6px;
        margin-right: 10px;
      }

      p {
        margin-bottom: 4px;
        color: $primary2;
      }

      a {
        font-family: "f3";
      }
    }
  }
}

// Blog view end//
// Contact//
.cont-sess {
  h5 {
    margin: 10px;
    text-align: center;
    color: $primary2;
    text-transform: uppercase;
  }

  .cont-box {
    background: $white;
    box-shadow: 0 0 12px #00000029;
    padding: 15px;
    border-radius: 6px;

    .cont-cir {
      position: relative;

      li {
        margin-top: 20px;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: 40px;
          background: #4b8b0c;
          border-radius: 30%;
          padding: 8px;
          margin-right: 13px;
          box-shadow: 0 0 12px rgb(3 169 244 / 34%);
        }
      }

      .pos-abss {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  .form-box {
    background: $primary2;
    padding: 24px;
    height: 100%;
    border-radius: 8px;

    h5 {
      color: $white;
    }

    input {
      background-color: #375c86;
      padding: 12px;
      margin: 15px 0;
      border: 0;
      border-radius: 8px;
      color: $white;
    }

    .sub-bnt {
      background-color: $white !important;
      color: $primary2;
      font-family: "f3";
    }

    ::placeholder {
      color: #ffffff85;
      font-size: 14px;
    }
  }
}

// Contact end//
// privact  //
.privacy-ses {
  h5 {
    color: $primary2;
    text-align: start;
  }

  .pri-img {
    width: 30%;
    margin: auto;
  }

  .term-cond {
    li {
      display: flex;
      align-items: flex-start;
      text-align: justify;
      margin-top: 14px;
      line-height: 1.6;

      img {
        width: 20px;
        margin-right: 13px;
      }
    }

    b {
      font-family: "f3";
      font-size: 17px;
    }
  }

  .pad-les,
  .term-cond {
    padding-left: 2rem;
  }
}

// privact end//
.inves_img {
  width: 100px;
  margin: 10px 0;
}

// .modernWay {
//   height: 28px;
//   overflow: hidden;
// }
.modernWay {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 45px;
}

// Frontend design end  //

// update//
.ref_box {
  position: relative;

  svg {
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    top: 0;
    height: 91%;
  }
}

// update end//

// ityped start //
.ityped-cursor {
  font-size: 2.2rem;
  opacity: 1;
  -webkit-animation: blink 0.3s infinite;
  -moz-animation: blink 0.3s infinite;
  animation: blink 0.3s infinite;
  animation-direction: alternate;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  100% {
    opacity: 0;
  }
}

.caro-sel {
  z-index: 0;
  position: relative;

  .carousel-inner {
    // background: #000;

    img {
      // opacity: .8;
      object-fit: cover;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }

  .ban-cap {
    position: absolute;
    right: auto;
    bottom: auto;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    text-align: center;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
  }

  .carousel-caption {
    position: relative;
    right: auto;
    bottom: auto;
    left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #000;
    text-align: center;
    border-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
  }

  h6 {
    text-align: center;
    color: $white;
    font-size: 20px;
    // background: #fff;
    padding: 6px 15px;
    border-radius: 5px;
    margin: 0;
    text-shadow: 2px 2px 5px #000;
    text-transform: uppercase;
  }

  span {
    color: #5fbc5f;
    padding: 0 10px;
  }

  ::placeholder {
    // color: #fff;
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    text-shadow: 2px 2px 5px #000;
  }

  input {
    border: 0;
    background-color: transparent;
  }
}

.hom_ban {
  .cont-img img {
    object-fit: cover;
  }

  h5 {
    text-transform: inherit !important;
  }

  .logo-bang {
    position: absolute;
    right: 60px;
    bottom: 0;
    img {
      width: 108px;
      margin: 5px 0;
    }
  }
}

.inver_deses {
  .inves_deta {
    text-align: start;

    li {
      padding: 10px;
      position: relative;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      box-shadow: 0 0 10px #cce5f100;
      margin-top: 10px;

      &:hover {
        background: $white;
        box-shadow: 0 0 10px #cce5f1;
      }
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    display: none;
  }
}

.sets_des {
  .part-box .cir-prj {
    left: 19px;
  }
}

.own_des {
  h5 {
    color: $primary2;
    font-size: 25px;
    font-family: "f4";
  }

  p {
    line-height: 2;
    font-size: 16px;
    text-align: start;
  }

  ul {
    li {
      padding: 10px 0px 10px 15px;
      background: #ffffff;
      margin: 10px 0px;
      border-radius: 5px;
      box-shadow: 5px 5px 5px rgba(48, 124, 255, 15%);
    }
  }
}

.pc_ic {
  color: $primary1;
}

.box-leas {
  box-shadow: 0px 4px 15px rgb(2 2 2 / 15%);
  padding: 10px;
  border-radius: 6px;
  height: 100%;

  img {
    width: 150px;
  }
}

.abouts_iconss {
  .box-iocn {
    background: #dbeafb;
    width: 100px;
    height: 100px;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    margin: auto;
    border-radius: 10px;

    img {
      width: 50px;
    }
  }

  p {
    // text-align: justify;
    line-height: 1.6;
    opacity: 0.8;
  }
}

// ityped end //
.user-bg {
  width: 45px;
  height: 45px;
  background: linear-gradient(132deg, $primary2, $blue);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px #34333340;
  position: relative;
  margin: 0;

  img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    // margin-top: 7px;
  }
}

.user-name {
  font-size: 16px;
  font-family: "f2";
  margin-bottom: 0;
  padding: 0 10px;
}

.ref-side {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.abouts_iconss {
  h5 {
    font-size: 16px;
    text-align: center;
    color: $primary2;
  }
}

.our-team {
  h5 {
    font-size: 20px;
    color: $primary2;
  }
}

.for-box {
  background: #fff;
  padding: 10px;
  box-shadow: 0px 0px 12px #2196f33b;
  border-radius: 5px;

  .img-box {
    img {
      width: 110px;
    }

    h6 {
      font-size: 20px;
    }
  }
}

.click-here {
  position: relative;
  // overflow: hidden;

  .form-box {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0;

    img {
      width: 20px;
    }
  }
}

.flow-conten {
  position: relative;

  h1 {
    font-size: 70px;
    color: #00000021;
    opacity: 0;
  }

  .flow-abs {
    position: absolute;
    top: 38px;
  }

  // h4 {
  //   color: $primary2;
  // }
}

.pad-legy {
  padding-left: 50px;
}

.pad-sec {
  padding-left: 80px;
}

.pad-third {
  padding-left: 110px;
}

.pad-fit {
  padding-left: 30px;
}

.flip-design {
  p {
    padding-left: 13px;
  }

  img {
    filter: invert(1);
    transform: rotate(180deg);
    width: 50px;
  }

  li {
    margin-top: 10px;
    list-style-type: disc;
  }
}
.bg-sha {
  background: $white;
  padding: 12px;
  box-shadow: 0 0 9px #00000026;
  border-radius: 4px;
}

.blogs5 {
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}
.nav_a {
  position: relative;

  .ab-dez {
    position: absolute;
    right: 50px;
    top: 0;
    opacity: 0;
    svg {
      font-size: 40px;
    }
  }
}

// .refer{

// }

.logo_image_cust {
  height: 100% !important;
}
.zindex_custom {
  z-index: 100 !important;
}
.shadow_custom {
  z-index: 100 !important;
  // background: $white;
  box-shadow: 0 7px 20px rgba(0, 0, 0, 0.18);
}

.custom-index {
  position: relative;
  z-index: 0;
}
.Content-Cont {
  height: 100% !important;
  width: 50% !important;
  align-items: center !important;
  justify-content: center !important;
}

.ban-cap2 {
  position: absolute;
  right: auto;
  bottom: auto;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: center;
  border-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
}
.Content-Cont {
  height: 100% !important;
  width: 80% !important;
  align-items: end;

  // position: absolute !important;
  // top: 0 !important;
}
.image-size-custom {
  height: 100%;
  width: 100%;
}

.rio_tab2 {
  z-index: 0 !important;
}

.box-shadow-custom {
  box-shadow: 0 7px 16px rgba(176, 220, 255, 0.18) !important;
}
.margin-t {
  margin-top: 4.3rem;
}

.banner-bg {
  height: 100% !important;
  width: 100% !important;
  background-color: #98f3f4 !important;
}
.custom-size {
  height: 60px !important;
  width: 60px !important;
}
.custom-size2 {
  height: 170px !important;
  width: 170px !important;
  align-items: center !important;
  justify-content: center !important;
}
.custom-size3 {
  height: 35% !important;
  width: 25% !important;
  align-items: center !important;
  justify-content: center !important;
  // background-color: pink;
}
.custom-size3 {
  h4 {
    font-family: "f2";
    font-size: 15px;
    margin: 10px 0;
    // color: $white;
  }
  img {
    width: 57px;
    // margin-bottom: 25px;
  }
}
.font-size-cust {
  font-size: 39px !important;
}
.text-shadow {
  text-shadow: 2px 2px 5px #ffffff !important;
}
h6 {
  ::placeholder {
    color: rgb(0, 0, 0) !important;
    font-size: 18px !important;
    text-transform: uppercase !important;
    text-shadow: 2px 2px 5px #ffffff !important;
  }
}
.col-size-custom {
  height: 150px !important;
  width: 150px !important;
  background-color: red !important;
}

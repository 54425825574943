@font-face {
  font-family: "f1";
  src: url("../fonts/Overpass-Regular.ttf");
}
@font-face {
  font-family: "f2";
  src: url("../fonts/Overpass-Medium.ttf");
}
@font-face {
  font-family: "f3";
  src: url("../fonts/Overpass-Bold.ttf");
}
@font-face {
  font-family: "f4";
  src: url("../fonts/Overpass-ExtraBold.ttf");
}
@font-face {
  font-family: "f5";
  src: url("../fonts/Overpass-Black.ttf");
}

@import "./assets/sass/fonts.scss";
@import "./assets/sass/theme.scss";

@media (max-width: 767px) {
  .login-page {
    .cent {
      height: 0;
    }

    .login-ses {
      .login-bg {
        background-position: top;
        height: auto;
        justify-content: center;
        padding: 30px;
        position: static;
        width: 100%;
      }
    }

    .log-img {
      .login-ab {
        position: inherit;
        top: 0;
        left: auto;
        transform: inherit;
      }
    }
  }

  .ref_box {
    border: 0px solid #0a3f89 !important;
  }

  .img_pos {
    .abs_img {
      display: none;
    }
  }

  .hom_ban {
    height: 50vh;
  }
}

@media (max-width: 991px) {
  .dropdown-items1 {
    color: #fff !important;
  }
  .inverst-ment .linking {
    color: #fff;
  }
  .nav-design {
    .min-nav {
      position: absolute;
      top: 70px;
      background: $primary2;
      padding: 20px;
      left: -200px;
      height: 100vh;
      width: 100%;
      clip-path: circle(50px at 10% -30%);
      transition: 0.4s all;
      z-index: 11;

      &.show {
        left: 0px;
        transition: 1s all;
        clip-path: circle(690px at 0% -10%);
      }

      .nav_a a {
        color: $white !important;
      }
      .dropdown-item {
        color: $black !important;
      }
      .dropdown-menu {
        width: 50%;
        background: $black;
      }
      .ab-dez {
        opacity: 1;
      }
    }
  }

  .fron-nav .logo_imag {
    left: 5% !important;
    position: inherit !important;
  }

  .fro_bt {
    display: inline-block;
    margin-top: 10px;
  }
}

@media (max-width: 567px) {
  .top-header .head-user .user-bg {
    width: 50px;
    height: 50px;

    img {
      width: 48px !important;
    }
  }

  .caro-sel {
    h6 {
      font-size: 16px;
    }
  }
  .hom_ban .logo-bang {
    right: 25px;
    bottom: 10px;
  }
}

@media (max-width: 1300px) {
  .reg-bg {
    width: 100% !important;
  }

  .progra-line {
    right: 15% !important;
  }
}

// @media (min-width:1200px) {
//     .navbar-expand-lg .navbar-nav .nav-link {
//         padding-right: 1.5rem;
//         padding-left: 1.5rem;
//     }
// }

@media (min-width: 992px) {
  .dropdown-items1 {
    color: #000 !important;
  }

  .inverst-ment .linking {
    background: transparent !important;
    color: #000 !important;
  }

  .fron-nav .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

@media (min-width: 646px) and (max-width: 991px) {
  .our-deal {
    .cou_tree {
      .coud_leas {
        top: -5%;
        left: 18%;
      }

      .coud_leas1 {
        top: 10%;
        right: 17%;
      }
    }
  }
}

@media (max-width: 646px) {
  .our-deal {
    .cou_tree {
      img {
        width: 35%;
      }

      .coud_leas {
        width: auto;
        top: -6%;
        left: 11%;
      }

      .coud_leas1 {
        width: auto;
        top: 5%;
        right: 10%;
      }
    }
  }
}

@media (max-width: 400px) {
  .our-deal {
    .cou_tree {
      .coud_leas {
        left: 1%;

        h5 {
          font-size: 16px;
        }
      }

      .coud_leas1 {
        right: 1%;

        h5 {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 560px) {
  .col-custom {
    background-color: red;
    display: none;
  }
}
@media (max-width: 789px) {
  .cou-design {
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
  }
}
